import { Navigate, Route, Routes } from 'react-router-dom';

import { ApiDocsPage } from 'pages/ApiDocsPage';
import { CapabilitiesPage } from 'pages/CapabilitiesPage';
import { CapabilityDetailsPage } from 'pages/CapabilityDetailsPage';
import { IntakeFormPage } from 'pages/IntakeFormPage';
import { IntegrationPatternConfigurationPage } from 'pages/IntegrationPatternConfigurationPage';
import { LandingPage } from 'pages/LandingPage';
import { UserDashboardPage } from 'pages/UserDashboardPage';
import {
	API_DOCS_ROUTE,
	CAPABILITIES_ROUTE,
	CAPABILITY_DETAILS_ROUTE,
	HOME_ROUTE,
	INTAKE_ROUTE,
	INTEGRATION_PATTERN_CONFIGURATION_ROUTE,
	USER_DASHBOARD_ROUTE,
} from 'services/constants/routes';

export const AppRoutes = () => (
	<Routes>
		<Route index element={<LandingPage />} />
		<Route path={USER_DASHBOARD_ROUTE} element={<UserDashboardPage />} />
		<Route path={CAPABILITIES_ROUTE} element={<CapabilitiesPage />} />
		<Route path={CAPABILITY_DETAILS_ROUTE} element={<CapabilityDetailsPage />} />
		<Route path={INTEGRATION_PATTERN_CONFIGURATION_ROUTE} element={<IntegrationPatternConfigurationPage />} />
		<Route path={API_DOCS_ROUTE} element={<ApiDocsPage />} />
		<Route path={INTAKE_ROUTE} element={<IntakeFormPage />} />
		<Route path="*" element={<Navigate to={HOME_ROUTE} />} />
	</Routes>
);
