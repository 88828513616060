import { StatusIconProtoProps } from '@nike/eds';
import { ReactNode } from 'react';

export enum SortDirection {
	ASC = 'asc',
	DESC = 'desc',
}

export interface FilterOptions {
	searchFilter: string;
	sortField: string;
	sortDirection: SortDirection;
}

export interface Snack {
	id: string;
	content: ReactNode | string;
	status: StatusIconProtoProps['status'];
}

export interface VisualDescription {
	imgLink: string;
	alt: string;
}

export interface SelectItem {
	value: string;
	label: string;
}

export type ValueType = null | string | number | boolean | string[] | SelectItem | SelectItem[];

export enum FieldType {
	OBJECT = 'object',
	ENUM = 'enum',
	ARRAY = 'array',
	NUMBER = 'number',
	STRING = 'string',
	BOOLEAN = 'boolean',
	INTEGER = 'integer',
	DATE = 'date',
}

export enum RequestType {
	INTAKE = 'intake',
}

export interface PayloadType {
	[key: string]: string | number | boolean | string[] | PayloadType;
}

export interface IntegrationPayload {
	capabilityId: string;
	integrationPatternId: string;
	requestType: RequestType;
	user: string;
	intakePayload: PayloadType;
	configurationPayload: PayloadType;
}

export interface IntakeFormPayload {
	user: string;
	intakePayload: PayloadType;
}

export interface JiraPayload {
	link: string;
}

export interface Pages {
	next: string;
	prev: string;
	totalPages: number;
	totalResources: number;
}

export enum STEP_MODES {
	EDIT = 'EDIT',
	PREVIEW = 'PREVIEW',
	UNCOMPLETED = 'UNCOMPLETED',
	DISABLE = 'DISABLE',
}

export interface Field {
	key: string;
	path: string;
	description?: string;
	value: ValueType;
	isRequired: boolean;
	isSecure: boolean;
	isDisabled: boolean;
	isHidden: boolean;
	isConst: boolean;
	dirty?: boolean;
	error: string;
	type: string;
	label: string;
	default: ValueType | null;
	placeholder?: string;
	options?: SelectItem[];
	relatedFields?: RelatedField[];
	dependentRequired?: DependentRequiredField[];
	validation?: Validation;
}

export interface Validation {
	minLength?: number;
	maxLength?: number;
	minValue?: number;
	maxValue?: number;
	pattern?: {
		value: string;
		message?: string;
	};
}

export interface Step {
	title: string;
	error: string;
	formName: string;
	path: string;
	fields: Field[];
	mode: STEP_MODES;
	isActive: boolean;
	isHidden: boolean;
	relatedFields?: RelatedField[];
	dependentRequired?: DependentRequiredField[];
}

export enum InputType {
	TEXT = 'text',
	PASSWORD = 'password',
	NEW_PASSWORD = 'new-password',
	DATE = 'date',
}

export interface RelatedField {
	fields: string[];
	value: string;
}

export interface DependentRequiredField {
	path: string;
	value: string;
}

export interface Breadcrumb {
	label: string;
	url: string;
	isLoading: boolean;
}

export enum CapabilityResourcesMode {
	LIST = 'list',
	DETAILS = 'details',
}

export enum StatusIndicator {
	SUCCESS = 'success',
	NEUTRAL = 'neutral',
	WARNING = 'warning',
	DANGER = 'danger',
	INACTIVE = 'inactive',
}

export enum EmailFields {
	BUSINESS_OWNER_EMAILS = 'businessOwnerEmails',
	TECH_OWNER_EMAILS = 'techOwnerEmails',
	DATA_OWNER_EMAILS = 'dataOwnerEmails',
	ESCALATION_CONTACT = 'escalationContact',
}
