import { Box, Text } from '@nike/eds';
import { Trans, useTranslation } from 'react-i18next';

import { Loader } from 'components/common/Loader';
import { IntakeForm } from 'pages/IntakeFormPage/IntakeForm';
import { Step } from 'services/types/common';

export interface IntakeFormPageProps {
	steps: Step[];
	isLoading: boolean;
	onSubmit: () => void;
}

export const IntakeFormPage = ({ steps, isLoading, onSubmit }: IntakeFormPageProps) => {
	const { t } = useTranslation();

	return (
		<div data-testid="intake-form-page">
			{isLoading && <Loader type="in-container" />}
			<div className="eds-spacing--px-56 eds-spacing--pt-40">
				<Box className="eds-spacing--mb-24">
					<Text className="eds-spacing--mr-4 opacity-7" font="subtitle-1">
						{t('pages.intake_form.header_label')}
					</Text>
				</Box>
				{(!isLoading || !!steps.length) && (
					<Box className="eds-background--default eds-spacing--p-24 eds--radii-8 eds-spacing--mb-24">
						<Text className="eds-spacing--ml-8 eds-spacing--mb-16" font="body-2">
							<Trans
								i18nKey="pages.intake_form.body_message"
								components={{
									text: <b />,
								}}
							/>
						</Text>
						<IntakeForm steps={steps} onSubmit={onSubmit} />
					</Box>
				)}
			</div>
		</div>
	);
};
