import { createSelector } from '@reduxjs/toolkit';

import { State } from 'config/redux/store';
import { selectUserEmail } from 'services/store/user/UserSelectors';
import { IntakeFormPayload, PayloadType } from 'services/types/common';
import { getStepsPayload } from 'utils/utils';

export const selectIsLoadingIntakeFormSchema = (state: State) => state.intakeForm.isLoading;
export const selectIntakeFormSchema = (state: State) => state.intakeForm.schema;
export const selectIntakeFormError = (state: State) => state.intakeForm.error;
export const selectIntakeFormSteps = (state: State) => state.intakeForm.steps;
export const selectJiraLink = (state: State) => state.intakeForm.jiraLink;
export const selectIntakeForm = createSelector(
	[selectIntakeFormSteps, selectUserEmail],
	(steps, userEmail): IntakeFormPayload => ({
		user: userEmail,
		intakePayload: getStepsPayload(steps) as PayloadType,
	})
);
