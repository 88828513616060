import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { isStepModeEdit, isStepModePreview, isStepModeUncompleted } from 'components/common/Stepper/Stepper.utils';
import { StepperView } from 'components/common/Stepper/StepperView';
import { Step, STEP_MODES } from 'services/types/common';
import { updateFormSpepWithError } from 'utils/utils';

interface StepperContainerProps {
	steps: Step[];
	resultContent: ReactNode;
	submitButtonLabel: string;
	validateStep?: (step: Step) => boolean;
	renderEditContent: (step: Step) => ReactNode;
	onSubmit: () => void;
	onStepsChange: (steps: Step[]) => void;
}

export const StepperContainer = ({
	steps,
	resultContent,
	submitButtonLabel,
	validateStep,
	renderEditContent,
	onSubmit,
	onStepsChange,
}: StepperContainerProps) => {
	const [activeStepId, setActiveStepId] = useState(0);

	const filteredSteps = useMemo(() => steps.filter(({ isHidden }) => !isHidden), [steps]);

	useEffect(() => {
		if (Array.isArray(filteredSteps) && filteredSteps.length) {
			const activeId = filteredSteps.reduce((index, step, currentIndex) => {
				if (step.isActive) {
					return currentIndex;
				}

				return index;
			}, 0);
			const isAllFilled = filteredSteps.every(({ mode }) => isStepModePreview(mode) || isStepModeUncompleted(mode));

			setActiveStepId(activeId === 0 && isAllFilled ? filteredSteps.length + 1 : activeId);
		}
	}, [filteredSteps]);

	const resultStepIndex = useMemo(() => filteredSteps.length, [filteredSteps]);

	const resultStepMode = useMemo(() => {
		return activeStepId === filteredSteps.length + 1 ? STEP_MODES.EDIT : STEP_MODES.DISABLE;
	}, [activeStepId, filteredSteps]);

	const handleContinueClick = useCallback(
		({ currentStepIdx }: { currentStepIdx: number }) => {
			const nextStepIndex = currentStepIdx + 1;

			if (validateStep) {
				const currentStep = filteredSteps.find((_, idx) => currentStepIdx === idx);
				if (currentStep) {
					const isValidStep = validateStep(currentStep);
					const stepIndexToValidate = steps.reduce((stepIndex, step, index) => {
						if (stepIndex) {
							return stepIndex;
						}

						if (currentStep.path === step.path) {
							return index;
						}

						return stepIndex;
					}, 0);

					if (!isValidStep) {
						const validatedSteps = updateFormSpepWithError(steps, stepIndexToValidate);
						onStepsChange(validatedSteps);
						return;
					}
				}
			}

			const updatedFilteredSteps = filteredSteps.map((step, idx) => {
				if (currentStepIdx === idx) {
					return { ...step, mode: STEP_MODES.PREVIEW, isActive: false };
				}

				if (nextStepIndex === idx) {
					return { ...step, mode: STEP_MODES.EDIT, isActive: true };
				}

				return step;
			});

			const updatedSteps = steps.map((step) => {
				const foundStep = updatedFilteredSteps.find(({ path }) => path === step.path);
				if (foundStep) {
					return {
						...step,
						mode: foundStep.mode,
						isActive: foundStep.isActive,
					};
				}

				return step;
			});

			onStepsChange(updatedSteps);
		},
		[filteredSteps, steps, onStepsChange, validateStep]
	);

	const handleStepClick = useCallback(
		(clickedStepIdx: number) => {
			const updatedFilteredSteps = filteredSteps.map((step, stepIdx) => {
				const { mode } = step;

				if (isStepModeEdit(mode as STEP_MODES)) {
					if (!validateStep) {
						return {
							...step,
							isActive: false,
							mode: STEP_MODES.PREVIEW,
						};
					}
					const isValidStep = validateStep(step);

					return {
						...step,
						isActive: false,
						mode: isValidStep ? STEP_MODES.PREVIEW : STEP_MODES.UNCOMPLETED,
					};
				}

				if (clickedStepIdx === stepIdx) {
					return { ...step, isActive: true, mode: STEP_MODES.EDIT };
				}

				return step;
			});

			const updatedSteps = steps.map((step) => {
				const foundStep = updatedFilteredSteps.find(({ path }) => path === step.path);
				if (foundStep) {
					return {
						...step,
						mode: foundStep.mode,
						isActive: foundStep.isActive,
					};
				}

				return step;
			});

			onStepsChange(updatedSteps);
		},
		[filteredSteps, steps, validateStep, onStepsChange]
	);

	return (
		<StepperView
			steps={filteredSteps}
			activeStepIdx={activeStepId}
			resultStepIdx={resultStepIndex}
			resultStepMode={resultStepMode}
			saveBtnTitlePath={submitButtonLabel}
			renderEditContent={renderEditContent}
			resultContent={resultContent}
			handleContinueClick={handleContinueClick}
			handleStepClick={handleStepClick}
			handleSaveClick={onSubmit}
		/>
	);
};
