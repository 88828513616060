import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as dispatch, useSelector as selector, TypedUseSelectorHook } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { rootReducer } from 'services/store';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'snacks',
		'roster',
		'capabilities',
		'capability',
		'integrationPattern',
		'integrationPatternConfiguration',
		'intakeForm',
	],
};
export const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
				ignoredPaths: ['auth.hasInitStarted'],
			},
		}),
	devTools: process.env.NODE_ENV !== 'production',
});

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export const useSelector: TypedUseSelectorHook<State> = selector;
export const useDispatch = () => dispatch<AppDispatch>();

export default store;
