import { Card, Text } from '@nike/eds';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DefaultImg from 'assets/imgs/default.jpg';

import styles from './CustomCard.module.scss';

interface CustomCardProps {
	message: string;
	title?: string;
	altMessage?: string;
	imgPath?: string;
	isLoading?: boolean;
	classNames?: string;
	textColor: 'white' | 'black';
	objectFit: 'cover' | 'contain';
	onClick: () => void;
}

export const CustomCard = ({
	message,
	title,
	isLoading,
	altMessage,
	imgPath,
	textColor,
	classNames,
	objectFit,
	onClick,
}: CustomCardProps) => {
	const { t } = useTranslation();
	const [img, setImg] = useState(DefaultImg);
	const [isImgLoading, setIsImgLoading] = useState(true);

	useEffect(() => {
		if (imgPath) {
			setIsImgLoading(true);
			const container = new Image();
			container.src = imgPath;
			container.onload = () => {
				setImg(imgPath);
				setIsImgLoading(false);
			};
			container.onerror = () => setIsImgLoading(false);
		} else {
			setIsImgLoading(false);
		}
	}, [imgPath]);

	return (
		<Card className={`${styles['card']} ${classNames ?? ''}`} onClick={onClick}>
			{isLoading || isImgLoading ? (
				<div data-testid="image-skeleton" className={`${styles['card-body-img']}`} />
			) : (
				<img
					className={`${styles['card-body-img']} full-height`}
					style={{ objectFit: img === DefaultImg ? 'cover' : objectFit }}
					src={img}
					loading="lazy"
					alt={altMessage ?? message}
				/>
			)}
			<div data-testid="info-section" className={styles['card-body-info']}>
				{isLoading || isImgLoading ? (
					<div data-testid="text-subtitle-skeleton" className="skeleton skeleton-text w100" />
				) : (
					<Text font="body-3" color={img === DefaultImg ? 'white' : textColor} className="opacity-7">
						{title ? title : t('common.view_label')}
					</Text>
				)}
				{isLoading || isImgLoading ? (
					<div data-testid="text-title-skeleton" className="skeleton skeleton-text full-width" />
				) : (
					<Text data-testid="custom-card-message" font="title-6" color={img === DefaultImg ? 'white' : textColor}>
						{message}
					</Text>
				)}
			</div>
		</Card>
	);
};
