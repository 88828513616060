import { Box, Text } from '@nike/eds';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import IntakeImg from 'assets/imgs/gocs.jpg';
import ProductInformationCapabilityImg from 'assets/imgs/landing-brand.jpg';
import { CustomCard } from 'components/common/CustomCard';
import { Loader } from 'components/common/Loader';
import { useDispatch, useSelector } from 'config/redux/store';
import { getCapabilities } from 'services/api/mos-configurator/MosConfiguratorApi';
import { getCapabilityDetailsRoute, INTAKE_ROUTE } from 'services/constants/routes';
import {
	selectCapabilities,
	selectCapabilitiesError,
	selectIsLoadingCapabilities,
} from 'services/store/capabilities/CapabilitiesSelectors';
import { clearCapabilitiesError } from 'services/store/capabilities/CapabilitiesSlice';
import { useSnack } from 'utils/hooks/snack';

import styles from './LandingPage.module.scss';

export const LandingPage = () => {
	const snack = useSnack();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const error = useSelector(selectCapabilitiesError);
	const capabilities = useSelector(selectCapabilities);
	const isLoading = useSelector(selectIsLoadingCapabilities);

	useEffect(() => {
		if (!capabilities.length) {
			dispatch(getCapabilities());
		}
	}, [capabilities, dispatch]);

	useEffect(() => {
		if (error) {
			snack.error(error);
			dispatch(clearCapabilitiesError());
		}
	}, [error, snack, dispatch]);

	const handleNavigate = (route: string) => () => navigate(route);

	const productInformationRoute = useMemo(() => {
		const productInformationCapabilityId = capabilities.find((capability) =>
			capability.name.includes('Product Information')
		)?.capabilityId;

		return getCapabilityDetailsRoute(productInformationCapabilityId as string);
	}, [capabilities]);

	return (
		<Box data-testid="landing-page" className={styles['container']}>
			{isLoading && <Loader type="in-container" />}
			<Box className={styles['container-overlay']}>
				<Box className="eds-spacing--px-56 eds-spacing--pt-56">
					<Box className="eds-spacing--mb-40">
						<Box className="eds-spacing--pt-20 eds-spacing--mb-40">
							<Text font="title-1" className="eds-spacing--mb-20 eds-color--white capitalize">
								{t('pages.landing.title_label')}
							</Text>
							<Text font="title-1" className="eds-color--white capitalize">
								{t('pages.landing.subtitle_label')}
							</Text>
						</Box>
						<Box className="mw700">
							<Text className="eds-color--white line-height--1-5">{t('pages.landing.description_label')}</Text>
						</Box>
					</Box>
				</Box>
			</Box>

			<Box className="eds-spacing--px-56 eds-spacing--pt-56">
				<Box className="eds-grid eds-grid--m-cols-2 eds-grid--items-center">
					<CustomCard
						imgPath={ProductInformationCapabilityImg}
						classNames="h400"
						objectFit="cover"
						textColor="white"
						title={t('pages.landing.file_integration_card_title')}
						message={t('pages.landing.file_integration_card_description')}
						altMessage={t('pages.landing.file_integration_card_description')}
						onClick={handleNavigate(productInformationRoute)}
					/>
					<CustomCard
						imgPath={IntakeImg}
						classNames="h400"
						objectFit="cover"
						textColor="white"
						title={t('pages.landing.intake_form_card_title')}
						message={t('pages.landing.intake_form_card_description')}
						altMessage={t('pages.landing.intake_form_card_description')}
						onClick={handleNavigate(INTAKE_ROUTE)}
					/>
				</Box>
			</Box>
		</Box>
	);
};
