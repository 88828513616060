import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createOldWayIntake, getOldWayIntakeFields } from 'services/api/mos-configurator/MosConfiguratorApi';
import { ResponseError } from 'services/types/Api';
import { EmailFields, Field, JiraPayload, Step } from 'services/types/common';
import { IntakeForm, Schema } from 'services/types/IntegrationPattern';
import { updateFormStepValueByPath } from 'utils/utils';

export interface IntakeFormState {
	isLoading: boolean;
	error: string;
	schema: Schema | null;
	steps: Step[];
	jiraLink: string;
}

export const initialState: IntakeFormState = {
	isLoading: false,
	error: '',
	schema: null,
	steps: [],
	jiraLink: '',
};

const intakeFormSlice = createSlice({
	name: 'intakeFormSlice',
	initialState,
	reducers: {
		resetIntakeFormState(state: IntakeFormState) {
			state.error = '';
			state.schema = initialState.schema;
			state.jiraLink = initialState.jiraLink;
			state.steps = initialState.steps;
		},
		setIntakeFormSteps(state: IntakeFormState, { payload }: PayloadAction<{ steps: Step[] }>) {
			state.steps = payload.steps;
		},
		updateIntakeFormStepField(state: IntakeFormState, { payload }: PayloadAction<{ field: Field }>) {
			state.steps = updateFormStepValueByPath(state.steps, payload.field);
		},
		setPresetFields(state: IntakeFormState, { payload }: PayloadAction<{ email: string }>) {
			const { email } = payload;
			const steps = state.steps;
			const emailFields = steps?.reduce((foundFields: Field[], step) => {
				const { fields = [] } = step;
				fields.forEach((field) => {
					if (
						[
							EmailFields.BUSINESS_OWNER_EMAILS,
							EmailFields.TECH_OWNER_EMAILS,
							EmailFields.DATA_OWNER_EMAILS,
							EmailFields.ESCALATION_CONTACT,
						].includes(field.key as EmailFields)
					) {
						foundFields.push(field);
					}
				});
				return foundFields;
			}, []);
			const isEmpty = emailFields?.every((field) => Array.isArray(field?.options) && !field?.options.length);
			if (isEmpty) {
				const options = [{ value: email, label: email }];
				const updatedSteps = emailFields?.reduce((newSteps: Step[], field) => {
					return updateFormStepValueByPath(newSteps, {
						...field,
						value: [EmailFields.BUSINESS_OWNER_EMAILS, EmailFields.TECH_OWNER_EMAILS].includes(field.key as EmailFields)
							? options
							: [],
						options,
					});
				}, steps);
				state.steps = updatedSteps;
			}
		},
		clearIntakeFormError(state: IntakeFormState) {
			state.error = '';
		},
		clearJiraLink(state: IntakeFormState) {
			state.jiraLink = '';
		},
		clearIntakeFormSteps(state: IntakeFormState) {
			state.steps = [];
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getOldWayIntakeFields.pending, (state: IntakeFormState) => {
				state.isLoading = true;
			})
			.addCase(getOldWayIntakeFields.fulfilled, (state: IntakeFormState, { payload }: PayloadAction<IntakeForm>) => {
				const { schema } = payload?.objects || {};
				state.isLoading = false;
				state.schema = schema;
			})
			.addCase(getOldWayIntakeFields.rejected, (state: IntakeFormState, { payload }) => {
				state.isLoading = false;
				state.error = (payload as ResponseError).errorMessage;
			})
			.addCase(createOldWayIntake.pending, (state: IntakeFormState) => {
				state.isLoading = true;
			})
			.addCase(createOldWayIntake.fulfilled, (state: IntakeFormState, { payload }: PayloadAction<JiraPayload>) => {
				state.isLoading = false;
				state.jiraLink = payload?.link || '';
			})
			.addCase(createOldWayIntake.rejected, (state: IntakeFormState, { payload }) => {
				state.isLoading = false;
				state.error = (payload as ResponseError).errorMessage;
			});
	},
});

export const {
	setIntakeFormSteps,
	setPresetFields,
	clearIntakeFormSteps,
	clearJiraLink,
	resetIntakeFormState,
	clearIntakeFormError,
	updateIntakeFormStepField,
} = intakeFormSlice.actions;

export default intakeFormSlice.reducer;
