import { createAsyncThunk } from '@reduxjs/toolkit';

import { http } from 'config/axios';
import {
	getCapabilitiesUrl,
	getCapabilityUrl,
	getConfigurationRequestsUrl,
	getIntakeFieldsUrl,
	getIntegrationPatternSchemaUrl,
	getIntegrationPatternUrl,
	getOldWayIntakeFieldsUrl,
	getOldWayIntakeRequestsUrl,
} from 'services/constants/api';
import { IntakeFormPayload, IntegrationPayload } from 'services/types/common';

export const getCapabilities = createAsyncThunk(
	'[Capabilities] Get list of capabilities',
	async (_, { rejectWithValue }) => {
		try {
			const url = getCapabilitiesUrl();

			const { data } = await http.get(url);
			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getCapability = createAsyncThunk(
	'[Capability] Get capability',
	async ({ capabilityId }: { capabilityId: string }, { rejectWithValue }) => {
		try {
			const url = getCapabilityUrl(capabilityId);

			const { data } = await http.get(url);
			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getIntegrationPattern = createAsyncThunk(
	'[Integration Pattern] Get integration pattern',
	async ({ integrationPatternId }: { integrationPatternId: string }, { rejectWithValue }) => {
		try {
			const url = getIntegrationPatternUrl(integrationPatternId);

			const { data } = await http.get(url);
			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getIntakeFields = createAsyncThunk('[Intake Fields] Get intake fields', async (_, { rejectWithValue }) => {
	try {
		const url = getIntakeFieldsUrl();

		const { data } = await http.get(url);
		return data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const createIntegration = createAsyncThunk(
	'[Intake Form] Create integration',
	async ({ form }: { form: IntegrationPayload }, { rejectWithValue }) => {
		try {
			const url = getConfigurationRequestsUrl();

			const { data } = await http.post(url, form);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getIntegrationPatternSchema = createAsyncThunk(
	'[Integration Pattern] Get integration pattern schema',
	async ({ integrationPatternId }: { integrationPatternId: string }, { rejectWithValue }) => {
		try {
			const url = getIntegrationPatternSchemaUrl(integrationPatternId);

			const { data } = await http.get(url);
			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getOldWayIntakeFields = createAsyncThunk(
	'[Intake Form] Get intake form schema',
	async (_, { rejectWithValue }) => {
		try {
			const url = getOldWayIntakeFieldsUrl();
			const { data } = await http.get(url);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const createOldWayIntake = createAsyncThunk(
	'[Intake Form] Create intake form',
	async ({ form }: { form: IntakeFormPayload }, { rejectWithValue }) => {
		try {
			const url = getOldWayIntakeRequestsUrl();

			const { data } = await http.post(url, form);

			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
